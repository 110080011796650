// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-acquisition-js": () => import("./../../../src/pages/acquisition.js" /* webpackChunkName: "component---src-pages-acquisition-js" */),
  "component---src-pages-analysis-js": () => import("./../../../src/pages/analysis.js" /* webpackChunkName: "component---src-pages-analysis-js" */),
  "component---src-pages-ar-filter-js": () => import("./../../../src/pages/ar-filter.js" /* webpackChunkName: "component---src-pages-ar-filter-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-content-marketing-js": () => import("./../../../src/pages/content-marketing.js" /* webpackChunkName: "component---src-pages-content-marketing-js" */),
  "component---src-pages-conversion-js": () => import("./../../../src/pages/conversion.js" /* webpackChunkName: "component---src-pages-conversion-js" */),
  "component---src-pages-digital-ads-js": () => import("./../../../src/pages/digital-ads.js" /* webpackChunkName: "component---src-pages-digital-ads-js" */),
  "component---src-pages-digital-marketing-consultancy-js": () => import("./../../../src/pages/digital-marketing-consultancy.js" /* webpackChunkName: "component---src-pages-digital-marketing-consultancy-js" */),
  "component---src-pages-email-marketing-js": () => import("./../../../src/pages/email-marketing.js" /* webpackChunkName: "component---src-pages-email-marketing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kariyer-js": () => import("./../../../src/pages/kariyer.js" /* webpackChunkName: "component---src-pages-kariyer-js" */),
  "component---src-pages-landing-builder-js": () => import("./../../../src/pages/landing-builder.js" /* webpackChunkName: "component---src-pages-landing-builder-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-engine-optimization-js": () => import("./../../../src/pages/search-engine-optimization.js" /* webpackChunkName: "component---src-pages-search-engine-optimization-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-social-media-js": () => import("./../../../src/pages/social-media.js" /* webpackChunkName: "component---src-pages-social-media-js" */),
  "component---src-pages-start-project-js": () => import("./../../../src/pages/start-project.js" /* webpackChunkName: "component---src-pages-start-project-js" */),
  "component---src-pages-success-stories-arcadium-js": () => import("./../../../src/pages/success-stories/arcadium.js" /* webpackChunkName: "component---src-pages-success-stories-arcadium-js" */),
  "component---src-pages-success-stories-ecanta-js": () => import("./../../../src/pages/success-stories/ecanta.js" /* webpackChunkName: "component---src-pages-success-stories-ecanta-js" */),
  "component---src-pages-success-stories-fullamoda-js": () => import("./../../../src/pages/success-stories/fullamoda.js" /* webpackChunkName: "component---src-pages-success-stories-fullamoda-js" */),
  "component---src-pages-success-stories-kentpark-js": () => import("./../../../src/pages/success-stories/kentpark.js" /* webpackChunkName: "component---src-pages-success-stories-kentpark-js" */),
  "component---src-pages-success-stories-kuruyemis-borsasi-js": () => import("./../../../src/pages/success-stories/kuruyemis-borsasi.js" /* webpackChunkName: "component---src-pages-success-stories-kuruyemis-borsasi-js" */),
  "component---src-pages-success-stories-liva-pastacilik-case-study-js": () => import("./../../../src/pages/success-stories/liva-pastacilik-case-study.js" /* webpackChunkName: "component---src-pages-success-stories-liva-pastacilik-case-study-js" */),
  "component---src-pages-success-stories-liva-pastacilik-js": () => import("./../../../src/pages/success-stories/liva-pastacilik.js" /* webpackChunkName: "component---src-pages-success-stories-liva-pastacilik-js" */),
  "component---src-pages-success-stories-matbuu-js": () => import("./../../../src/pages/success-stories/matbuu.js" /* webpackChunkName: "component---src-pages-success-stories-matbuu-js" */),
  "component---src-pages-success-stories-pakmaya-js": () => import("./../../../src/pages/success-stories/pakmaya.js" /* webpackChunkName: "component---src-pages-success-stories-pakmaya-js" */),
  "component---src-pages-success-stories-robesnmore-js": () => import("./../../../src/pages/success-stories/robesnmore.js" /* webpackChunkName: "component---src-pages-success-stories-robesnmore-js" */),
  "component---src-pages-success-stories-sefa-stone-js": () => import("./../../../src/pages/success-stories/sefa-stone.js" /* webpackChunkName: "component---src-pages-success-stories-sefa-stone-js" */),
  "component---src-pages-success-stories-stm-js": () => import("./../../../src/pages/success-stories/stm.js" /* webpackChunkName: "component---src-pages-success-stories-stm-js" */),
  "component---src-pages-success-stories-tcdd-tasimacilik-js": () => import("./../../../src/pages/success-stories/tcdd-tasimacilik.js" /* webpackChunkName: "component---src-pages-success-stories-tcdd-tasimacilik-js" */),
  "component---src-pages-success-stories-yolcu-360-js": () => import("./../../../src/pages/success-stories/yolcu360.js" /* webpackChunkName: "component---src-pages-success-stories-yolcu-360-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-ui-ux-design-js": () => import("./../../../src/pages/ui-ux-design.js" /* webpackChunkName: "component---src-pages-ui-ux-design-js" */),
  "component---src-pages-web-development-js": () => import("./../../../src/pages/web-development.js" /* webpackChunkName: "component---src-pages-web-development-js" */),
  "component---src-templates-blog-detail-js": () => import("./../../../src/templates/blog-detail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */)
}

